import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import emailjs from "emailjs-com"

import BannerBG from "../images/contact-bg.png"

const Banner = styled.div`
  background-image: url(${BannerBG});
  background-position: top;
  background-size: auto 60%;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    background-size: cover;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 1024px) {
  }
`

const BannerLeft = styled.div`
  text-align: center;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h5 {
    margin: 1rem 0;
    padding: 0 1.6rem;
  }

  @media (min-width: 768px) {
    width: 60%;
  }
  @media (min-width: 1024px) {
    min-height: 40vh;
    width: 40%;
  }
`
const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .social-icon {
    margin: 0 0.5rem;
  }
`

const BannerRight = styled.div`
  background: ${props => props.theme.colors.primary};
  padding: 1rem;
  form {
    width: 90%;
    margin: 0 auto;
  }
  button {
    background-color: ${props => props.theme.colors.accent};
    width: 100%;
  }

  @media (min-width: 1024px) {
    max-width: 400px;
    margin: 2rem 0;
    width: 90%;
    form {
      width: 100%;
    }
  }
`

const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 0.4rem;

  label {
    margin-bottom: 2px;
    color: white;
  }
  input,
  textarea {
    padding: 7px;
    font-size: 1rem;
    border-radius: 3px;
    border: none;
  }
  textarea {
    height: 6rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-family: inherit;
  }
`

const MapWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    .maps {
      margin: 2rem 0;
    }
  }
  @media (min-width: 1024px) {
    .maps {
      margin: 3rem 0;
    }
  }
`

const ContactInfoWrap = styled.div`
  background-color: ${props => props.theme.colors.secondary};
  color: white;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .gatsby-image-wrapper {
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 0;
    h6 {
      font-size: ${props => props.theme.fontSize.p};
    }
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`
const ContactInfoItem = styled.div`
  margin: 0.5rem auto;
  max-width: 250px;
`

const ContactPage = () => {
  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        `${process.env.EMAILJS_SERVICE}`,
        `${process.env.EMAILJS_TEMPLATE}`,
        e.target,
        `${process.env.EMAILJS_USER}`
      )
      .then(
        result => {
          console.log(result.text)
          alert("Message was sent successfully! We will be in contact soon.")
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <Layout>
      <Seo title="Contact Us" />
      <Banner>
        <BannerLeft>
          <h1>Contact Us</h1>
          <h5>
            Our clinic strives to Restore and Maintain Health through Innovative
            Functional Medicine.
          </h5>
          <SocialIcons>
            <a
              href="https://www.facebook.com/qfmedwinkler/"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/fb-black.png"
                width={30}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Facebook icon"
                className="social-icon"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/juergen-winkler-md-abihm-a3487290"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../images/linkedin-black.png"
                width={30}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="LinkedIn icon"
                className="social-icon"
              />
            </a>
          </SocialIcons>
        </BannerLeft>
        <BannerRight>
          <form className="contact-form" onSubmit={sendEmail}>
            <input type="hidden" name="contact_number" />
            <FormInput>
              <label htmlFor="name">Name</label>
              <input type="text" name="name" required={true} />
            </FormInput>
            <FormInput>
              <label htmlFor="email">Email</label>
              <input type="email" name="email" required={true} />
            </FormInput>
            <FormInput>
              <label htmlFor="phone">Phone</label>
              <input type="text" name="phone" required={true} />
            </FormInput>
            <FormInput>
              <label htmlFor="message">Message</label>
              <textarea name="message" required={true}></textarea>
            </FormInput>
            <button type="submit">Submit</button>
          </form>
        </BannerRight>
      </Banner>
      <ContactInfoWrap>
        <ContactInfoItem>
          <StaticImage
            src="../images/phone-icon.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
          />
          <h6>(760) 585 - 4616</h6>
        </ContactInfoItem>
        <ContactInfoItem>
          <StaticImage
            src="../images/fax-icon.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
          />
          <h6>(760) 444 - 1858</h6>
        </ContactInfoItem>
        <ContactInfoItem>
          <StaticImage
            src="../images/location-icon.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
          />
          <h6>
            5814 Van Allen Way,
            <br />
            Carslbad, CA 92008, Suite 212
          </h6>
        </ContactInfoItem>
        <ContactInfoItem>
          <StaticImage
            src="../images/hours-icon.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
          />
          <h6>
            MON, TUES, THURS, FRI
            <br />
            08:00am - 04:00 pm
          </h6>
        </ContactInfoItem>
      </ContactInfoWrap>
      <MapWrapper>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3341.0271467467437!2d-117.2897774843703!3d33.13465138086559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dc73c873a770f9%3A0xbdc08a8d8ebf4b05!2sQuantum%20Functional%20Medicine!5e0!3m2!1sen!2sus!4v1620090487137!5m2!1sen!2sus"
          width="600"
          height="450"
          // allowfullscreen=""
          loading="lazy"
          className="maps"
          title="Quantum Maps Location"
        ></iframe>
      </MapWrapper>
    </Layout>
  )
}

export default ContactPage
